.contact-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    font-size: 1.2rem;
    width: 100%;
    padding-bottom: 5px;

    @media screen and (max-width:  1500px) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        font-size: 1.2rem;
        width: auto;
        margin-left: 5px;
    }

    @media screen and (max-width: 600px) {
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 1rem;
        font-size: 1.2rem;
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 430px) {
        margin-bottom: 13rem;
       
    }
    @media screen and (max-width: 360px) {
        margin-bottom: 2rem;
     
    }

    .contact-icon {    
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        font-size: 1.5rem;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid var(--primary-a);

        @media screen and (min-width: 1500px) {
            width: 31px;
            height: 31px;
            padding: 15px;
        }

        &:hover {
            border: 1px solid var(--primary);
        }

        a svg {
            color: var(--primary-a);
            width: 20px;
            height: 20px;
            margin-bottom: -3px;
            transition: all 0.3s ease-in-out; 
            padding-bottom: 2px;
            scale: 1.1;
            border-radius: 30%;

            @media screen and (min-width: 1500px) {
                width: 22px;
                height: 22px;
            }

            &:hover {
                color: var(--primary);
            }
        }
    
        .contact-icon-portfolio a svg {
            scale: 0.9;
        }   
    }
}