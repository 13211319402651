.wrong-letters-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
   .wrong-letters {
        font-size: 1.4rem;
        color: var(--primary);
        text-align: center;

        @media screen and (max-width: 300px) {
            font-size: 1rem;
        }

        p {
            margin: 0 0 5px;
            text-align: center;
        }

        span {
            font-size: 1.4rem;
        }
   }
}