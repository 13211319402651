.notification-container {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 10px 10px 0 0;
    padding: 5px 20px;
    position: absolute;
    bottom: -50px;
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.3s ease-in-out;
    z-index: 2;
    text-align: center;
    width: 30%;
    display: none;

    @media screen and (max-width: 600px) {
       width: 100%;
       bottom: 10rem;
       border-radius: 10px;
    }
}
  
.notification-container.show {
    transform: translateY(-50px);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
