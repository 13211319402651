.word {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .letter {
        border-bottom: 3px solid var(--primary);
        display: inline-flex;
        font-size: 30px;
        align-items: center;
        justify-content: center;
        margin: 0 6px;
        height: 50px;
        width: 25px;

        @media screen and (max-width: 400px) {
            margin: 0 3px;
            height: 40px;
            width: 20px;
        }
    }
}