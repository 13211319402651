.categories-container {
    background-color: var(--bg);
    color: var(--primary);
    border-radius: 3px;
    padding-left: 10px;
    font-size: 1.1rem;
    border: 1px solid var(--primary);

    .dropdown {
        background-color: transparent;
        color: var(--primary);
        border-radius: 5px;
        border: none;
        border: none;
        font-size: 1rem;
        text-align: center;
        padding-right: 5px;
        font-size: 1.1rem;

        &:focus {
            outline: none;
        }
    }

    .btn-change-category, .btn-apply-change {
        background-color: var(--bg);
        border: 1px solid var(--bg);
        border-left: 1px solid var(--primary);
        color: var(--primary);
        border-radius: 3px;
        padding: 2px 10px;
        font-size: 1.1rem;
        margin-left: 5px;

        &:hover {
            cursor: pointer;
            border: 1px solid var(--white);
            color: var(--white);
            transition: 0.4s;
        }
    }
}